import React from "react";
import banner_8 from "../../../../webassets/img/pricing-starter.png";
import { useDispatch } from "react-redux";
import {
  setPaymentModalVisible,
  setPricingModalVisible,
  setSelectedPlan,
  setSelectedPlanAmount,
} from "src/redux/slices/PlanSlice";
import { useSelector } from "react-redux";

function StarterPlanCard() {
  const dispatch = useDispatch();
  const selectedPlan = useSelector((state) => state.plans.selectedPlan);
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  return (
    <div
      className={`${
        paymentModalVisible ? "col-lg-8" : "col-lg-4"
      } col-md-6 paymentForm-allUICard`}
      data-aos="zoom-in"
      data-aos-delay={200}
    >
      <div className="box paymentForm-box">
        <h3 style={{ color: "#65c600" }} className="mb-0">
          Starter Plan
        </h3>
        <div className="price">
          <sup>₹</sup>4999<span> +GST / mo</span>
        </div>
        <img src={banner_8} className="img-fluid" alt="" />
        <ul>
          <li>1 Administrator</li>
          <li>3 Team Members</li>
          <li>10 Mobile App Accounts</li>
          <li>10 Projects</li>
          <li>5GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹275/mo</li>
          <li className="na">Additional Administrators</li>
          <li className="na">Add Team Members</li>
        </ul>
        {selectedPlan == null ? (
          <a
            href="#"
            className="btn-buy"
            onClick={() => {
              dispatch(setSelectedPlan("Starter"));
              dispatch(setSelectedPlanAmount(4999));
              dispatch(setPricingModalVisible(false));
              dispatch(setPaymentModalVisible(true));
              localStorage.setItem("selectedPlan", "Starter");
            }}
          >
            Select
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default StarterPlanCard;
