import React, { Component, Suspense, useEffect, useState, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import LoginPage from "./components/Login/LoginPage";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setProjectID,
  setUserName,
} from "./redux/slices/DashboardSlice";
import { setWeavyUrl } from "./redux/slices/ProjectSlice";
import axios from "axios";
import PrivacyPolicy from "./components/webpages/policies/PrivacyPolicy";
import RefundPolicy from "./components/webpages/policies/RefundPolicy";
import TermsPolicy from "./components/webpages/policies/TermsPolicy";
import PaymentFailurePage from "./components/PaidPlans/PaymentFailurePage";
import PayUSuccessURLPage from "./components/PaidPlans/PayUredirectURLPages/PayUSuccessURL";
import SignUpSuccessURL from "./components/PaidPlans/PayUredirectURLPages/SignUpSuccessURL";
import ForgotPassword from './components/Login/ForgotPassword.jsx';
import SignIn from './components/Login/SignIn.jsx';
import UpgradePaymentForm from "./components/UpgradePaymentForm";

import RenewalReminderPage from "./components/Login/RenewalRemainderPage";
import TermsAndConditions from "./views/terms-and-conditions/TermsAndConditions";
//import ReportsCard from "./views/reports-page/Reports.jsx";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const AboutUs = React.lazy(() => import("./components/webpages/AboutUs"));
const ContactUs = React.lazy(() => import("./components/webpages/ContactUs"));
const SignUp = React.lazy(() => import("./components/Login/LoginPage"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {    
  // const location = useLocation();
  useEffect(() => {
    // Generate a unique session ID
    const sessionId = new Date().getTime();
    localStorage.setItem("sessionId", sessionId);

    // Increase the session count
    const sessionCount = parseInt(localStorage.getItem("sessionCount") || "0");
    localStorage.setItem("sessionCount", sessionCount + 1);
    localStorage.setItem("firstAppLoad", true);

    const handleUnload = () => {
      // Set a sessionStorage flag indicating the tab is closed
      sessionStorage.setItem('isTabClosed', 'true');
    };

    // Handle the tab closing
    const handleBeforeUnload = () => {
      // const sessionCount = parseInt(localStorage.getItem("sessionCount"));
      // const firstAppLoad = localStorage.getItem("firstAppLoad");
      // if (sessionCount == 1 && firstAppLoad == "true") {
      //   localStorage.setItem("firstAppLoad", false);
      //   return;
      // }
      const signUpPlanTxnId = localStorage.getItem("signUpTxnId");
      const lastRenewTxnId = localStorage.getItem("lastRenewTxnId");
      const lastUpgradeTxnId = localStorage.getItem("lastUpgradeTxnId");
      const lastAdditionalStorageTxnId = localStorage.getItem(
        "lastAdditionalStorageTxnId"
      );
      const lastAdditionalUsersTxnId = localStorage.getItem(
        "lastAdditionalUsersTxnId"
      );
      const sessionCount = parseInt(localStorage.getItem("sessionCount"));
      const AID = localStorage.getItem("AID");

      if (signUpPlanTxnId) {
        const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
        localStorage.setItem("sessionCount", count);
        return;
      }

      if (lastRenewTxnId) {
        const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
        localStorage.setItem("sessionCount", count);
        return;
      }

      if (lastUpgradeTxnId) {
        const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
        localStorage.setItem("sessionCount", count);
        return;
      }

      if (lastAdditionalStorageTxnId) {
        const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
        localStorage.setItem("sessionCount", count);
        return;
      }

      if (lastAdditionalUsersTxnId) {
        const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
        localStorage.setItem("sessionCount", count);
        return;
      }

      if (!AID) {
        localStorage.clear();
        return;
      }

      // if (AID) {
      //   return;
      // }

      const count = parseInt(localStorage.getItem("sessionCount") || "0") - 1;
      localStorage.setItem("sessionCount", count);

      // console.log("location pathname", location.pathname);
      const currentPathName = window.location.pathname;
      //console.log("location", window.location.pathname);

      if (AID && currentPathName.includes("mywallzehnspace")) {
        return;
      }

      if (AID && currentPathName.includes("success")) {
        return;
      }

      if (AID && currentPathName.includes("payUFail")) {
        return;
      }

      /* If this was the last tab, clear the session storage
      if (sessionStorage.getItem('isTabClosed') === 'true') {
        localStorage.clear();
      }*/
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Check if sessionStorage is empty (i.e., not a refresh)
    if (!sessionStorage.getItem('isTabClosed')) {
      // Page is being loaded or refreshed
      sessionStorage.setItem('isTabClosed', 'false');
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleUnload();
    };
  }, []);
  // localStorage.clear();
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="/about" name="About" element={<AboutUs />} />
            <Route path="/contact" name="Contact" element={<ContactUs />} />
            <Route path="/signin" name="Signin" element={<SignUp />} />
            <Route path="/privacy" name="Privacy" element={<PrivacyPolicy />} />
            <Route path="/refund" name="Refund" element={<RefundPolicy />} />
            <Route path="/signin" name="signin" element={<SignIn />} />
            <Route path="/forgot-password" name="ForgotPassword" element={<ForgotPassword />} />
            <Route path="/upgrade" name="UpgradePaymentForm" element={<UpgradePaymentForm />} /> 
            <Route path="/renewal-reminder" name="RenewalRemainder" element={<RenewalReminderPage />} />
            <Route path="/terms-and-conditions" name="TermsAndConditions" element={<TermsAndConditions />} />

            {/* <Route path="/reports" name="Reports" element={<ReportsCard />} /> */}



          <Route
              path="/successUrl"
              name="Refund"
              element={<PayUSuccessURLPage />}
            />
            <Route
              path="/successSignUp"
              name="Refund"
              element={<SignUpSuccessURL />}
            />
            <Route
              path="/payUFail"
              name="Refund"
              element={<PaymentFailurePage />}
            />
            <Route
              path="/termsPolicy"
              name="TermsAndConditionsPolicy"
              element={<TermsPolicy />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
