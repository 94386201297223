import React, { useState } from "react";
import { CButton, CCol, CRow } from "@coreui/react";
import ThankingImage from "../../../assets/images/login/ThankingImage.png";
import facebookIcon from "../../../assets/images/login/fb.png";
import linkedinIcon from "../../../assets/images/login/LinkedIn.png";
import youtubeIcon from "../../../assets/images/login/YouTube.png";
import { useDispatch } from "react-redux";
import axios from "axios";
import API_URL from "src/utils/urls";
import { useSelector } from "react-redux";
import { setLoader } from "src/redux/slices/UserRegistrationSlice";
import { setAccessToken, setUserName } from "src/redux/slices/DashboardSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { updatePaymentStatus } from "src/utils/paymentFunctions";
import { useNavigate } from "react-router-dom";

function PayUSuccessURLPage() {
  // redux hooks
  const navigate = useNavigate();
  const parentId = localStorage.getItem("parentId");

  // upgrade subscription session storages
  const selectedUpgradePlan = localStorage.getItem("selectedUpgradePlan");
  const selectedUpgradePlanDuration = localStorage.getItem(
    "selectedUpgradePlanDuration"
  );
  const upgradePlanAmountBeforeTax = localStorage.getItem(
    "upgradePlanAmountBeforeTax"
  );
  const upgradePlanAmountAfterTax = localStorage.getItem(
    "upgradePlanAmountAfterTax"
  );
  const lastUpgradeTxnId = localStorage.getItem("lastUpgradeTxnId");

  // renew subscription session storages
  const selectedRenewPlan = localStorage.getItem("selectedRenewPlan");
  const selectedRenewPlanDuration = localStorage.getItem(
    "selectedRenewPlanDuration"
  );
  const renewPlanAmountBeforeTax = localStorage.getItem(
    "renewPlanAmountBeforeTax"
  );
  const renewPlanAmountAfterTax = localStorage.getItem(
    "renewPlanAmountAfterTax"
  );
  const lastRenewTxnId = localStorage.getItem("lastRenewTxnId");
  const adminsCount = localStorage.getItem(
    "adminsCount"
  );
  const TeamMembersCount = localStorage.getItem(
    "TeamMembersCount"
  );
  const StorageQty = localStorage.getItem(
    "StorageQty"
  );
  // additional storage sessions
  const selectedAdditionalStoragePlan = localStorage.getItem(
    "selectedAdditionalStoragePlan"
  );
  const selectedAdditionalStorageQuantity = localStorage.getItem(
    "selectedAdditionalStorageQuantity"
  );
  const additionalStoragePlanAmountBeforeTax = localStorage.getItem(
    "additionalStoragePlanAmountBeforeTax"
  );
  const additionalStoragePlanAmountAfterTax = localStorage.getItem(
    "additionalStoragePlanAmountAfterTax"
  );
  const lastAdditionalStorageTxnId = localStorage.getItem(
    "lastAdditionalStorageTxnId"
  );

  // additional users sessions
  const additionalUsersAdminsCount = localStorage.getItem(
    "additionalUsersAdminsCount"
  );
  const additionalUsersTeamMembersCount = localStorage.getItem(
    "additionalUsersTeamMembersCount"
  );
  const additionalUsersAmountBeforeTax = localStorage.getItem(
    "additionalUsersAmountBeforeTax"
  );
  const additionalUsersAmountAfterTax = localStorage.getItem(
    "additionalUsersAmountAfterTax"
  );
  const lastAdditionalUsersTxnId = localStorage.getItem(
    "lastAdditionalUsersTxnId"
  );

  const upgradeSubscriptionAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/UpgradeSubscription`, {
          AID: parentId,
          plan: selectedUpgradePlan,
          duration: selectedUpgradePlanDuration,
          amountbeforeTax: upgradePlanAmountBeforeTax,
          amountafterTax: upgradePlanAmountAfterTax,
          payuTxnid: lastUpgradeTxnId,
        })
        .then(function (response) {
          console.log(
            "payment data success response:",
            response
          );
          if ((response.data.Result = "success")) {
            localStorage.setItem("plan", selectedUpgradePlan);
            localStorage.setItem("selectedUpgradePlan", null);
            localStorage.setItem("selectedUpgradePlanDuration", null);
            localStorage.setItem("upgradePlanAmountBeforeTax", null);
            localStorage.setItem("upgradePlanAmountAfterTax", null);
            // updatePaymentStatusForUpgradeSubsription();
            // const updatePaymentResponseForUpgradeSub = updatePaymentStatus(
            //   parentId,
            //   lastUpgradeTxnId,
            //   "success"
            // );
            // console.log(
            //   "updatePaymentResponseForUpgradeSub",
            //   updatePaymentResponseForUpgradeSub
            // );
            // localStorage.setItem("lastUpgradeTxnId", "");
            localStorage.removeItem("lastUpgradeTxnId");
            // if(updatePaymentResponseForUpgradeSub.data == 'success')
          }
        })
        .catch(function (error) {
          console.log(
            "additional Storage  payment data failure response:",
            error
          );
          localStorage.removeItem("lastUpgradeTxnId");
        });
    } catch (error) {
      console.log("upgrade response error:", error);
    }
  };

  const renewSubscriptionAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/RenewSubscription`, {
          AID: parentId,
          plan: selectedRenewPlan,
          duration: selectedRenewPlanDuration,
          amountbeforeTax: renewPlanAmountBeforeTax,
          amountafterTax: renewPlanAmountAfterTax,
          payuTxnid: lastRenewTxnId,
          adminCount: adminsCount,
          TeamMemberCount: TeamMembersCount,
          StorageQty: StorageQty,
        })
        .then(function (response) {
          console.log("renewal payment data success response:", response);
          if ((response.data.Result = "success")) {
            localStorage.setItem("selectedRenewPlan", null);
            localStorage.setItem("selectedRenewPlanDuration", null);
            localStorage.setItem("renewPlanAmountBeforeTax", null);
            localStorage.setItem("renewPlanAmountAfterTax", null);
            // const updatePaymentResponseForRenewSub = updatePaymentStatus(
            //   parentId,
            //   lastRenewTxnId,
            //   "success"
            // );
            // console.log(
            //   "updatePaymentResponseForUpgradeSub",
            //   updatePaymentResponseForRenewSub
            // );
            // localStorage.removeItem("lastRenewTxnId");
            localStorage.removeItem("lastRenewTxnId");
          }
        })
        .catch(function (error) {
          console.log("renewal payment data failure response:", error);
          localStorage.removeItem("lastRenewTxnId");
        });
    } catch (error) {
      console.log("error occured:", error);
    }
  };

  const buyAdditionalStorageAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/buyStorage`, {
          AID: parentId,
          plan: selectedAdditionalStoragePlan,
          quantity: selectedAdditionalStorageQuantity,
          amountbeforeTax: additionalStoragePlanAmountBeforeTax,
          amountafterTax: additionalStoragePlanAmountAfterTax,
          payuTxnid: lastAdditionalStorageTxnId,
        })
        .then(function (response) {
          console.log(
            "additional Storage payment data success response:",
            response
          );
          if ((response.data.Result = "Success")) {
            localStorage.setItem("selectedAdditionalStoragePlan", null);
            localStorage.setItem("selectedAdditionalStorageQuantity", null);
            localStorage.setItem("additionalStoragePlanAmountBeforeTax", null);
            localStorage.setItem("additionalStoragePlanAmountAfterTax", null);
            // const updatePaymentResponseForAdditionalStorage =
            //   updatePaymentStatus(
            //     parentId,
            //     lastAdditionalStorageTxnId,
            //     "success"
            //   );
            // console.log(
            //   "updatePaymentResponseForAdditionalStorage",
            //   updatePaymentResponseForAdditionalStorage
            // );
            // localStorage.setItem("lastAdditionalStorageTxnId", "");
            localStorage.removeItem("lastAdditionalStorageTxnId");
          }
        })
        .catch(function (error) {
          console.log(
            "additional Storage  payment data failure response:",
            error
          );
          localStorage.removeItem("lastAdditionalStorageTxnId");
        });
    } catch (error) {
      console.log("error occured:", error);
    }
  };

  const buyAdditionalUserAPI = async () => {
    try {
      await axios
        .post(`${API_URL}/buyAdditionalLicense`, {
          AID: parentId,
          admins: additionalUsersAdminsCount,
          teamMembers: additionalUsersTeamMembersCount,
          amountbeforeTax: additionalUsersAmountBeforeTax,
          amountafterTax: additionalUsersAmountAfterTax,
          payuTxnid: lastAdditionalUsersTxnId,
        })
        .then(function (response) {
          console.log(
            "additional Users payment data success response:",
            response
          );
          if ((response.data.Result = "Success")) {
            localStorage.setItem("additionalUsersAdminsCount", null);
            localStorage.setItem("additionalUsersTeamMembersCount", null);
            localStorage.setItem("additionalUsersAmountBeforeTax", null);
            localStorage.setItem("additionalUsersAmountAfterTax", null);
            // const updatePaymentResponseForAdditionalUsers = updatePaymentStatus(
            //   parentId,
            //   lastAdditionalUsersTxnId,
            //   "success"
            // );
            // console.log(
            //   "updatePaymentResponseForAdditionalUsers",
            //   updatePaymentResponseForAdditionalUsers
            // );
            // localStorage.setItem("lastAdditionalUsersTxnId", "");
            localStorage.removeItem("lastAdditionalUsersTxnId");
          }
        })
        .catch(function (error) {
          console.log("additional Users payment data failure response:", error);
          localStorage.removeItem("lastAdditionalUsersTxnId");
        });
    } catch (error) {
      console.log("error occured:", error);
    }
  };

  const updatePaymentStatusForUpgradeSubsription = async () => {
    await axios
      .post(`${API_URL}/updatePaymentStatus`, {
        AID: parentId,
        txnid: lastUpgradeTxnId,
        status: "success",
      })
      .then(function (response) {
        if (response.data == "success") {
          console.log("update payment after success ", response);
          localStorage.setItem("lastUpgradeTxnId", null);
        }
        console.log("update payment status response", response.data);
      })
      .catch(function (error) {
        console.log(
          "while update payment status to success, error occurs:",
          error
        );
        localStorage.setItem("lastUpgradeTxnId", null);
      });
  };

  useEffect(() => {
    // if (!(lastUpgradeTxnId == null || "")) {
    //   upgradeSubscriptionAPI();
    // }
    if (lastUpgradeTxnId) {
      upgradeSubscriptionAPI();
    }
  }, [lastUpgradeTxnId]);

  useEffect(() => {
    // if (!(lastRenewTxnId == null || "")) {
    //   renewSubscriptionAPI();
    // }
    if (lastRenewTxnId) {
      renewSubscriptionAPI();
    }
  }, [lastRenewTxnId]);

  useEffect(() => {
    // if (!(lastAdditionalStorageTxnId == null || "")) {
    //   buyAdditionalStorageAPI();
    // }
    if (lastAdditionalStorageTxnId) {
      buyAdditionalStorageAPI();
    }
  }, [lastAdditionalStorageTxnId]);

  useEffect(() => {
    // if (!(lastAdditionalUsersTxnId == null || "")) {
    //   buyAdditionalUserAPI();
    // }
    if (lastAdditionalUsersTxnId) {
      buyAdditionalUserAPI();
    }
  }, [lastAdditionalUsersTxnId]);

  // useEffect(() => {
  //   if (JSON.parse(lastUpgradeTxnId) != null) {
  //     upgradeSubscriptionAPI();
  //   }
  // }, [lastUpgradeTxnId]);

  // useEffect(() => {
  //   if (JSON.parse(lastRenewTxnId) != null) {
  //     renewSubscriptionAPI();
  //   }
  // }, [lastRenewTxnId]);

  // useEffect(() => {
  //   selectedPlan !== "Free" && AID !== "" && updatePaymentStatus();
  // }, [AID]);

  return (
    <div className="bg-light min-vh-100 d-flex flex-column justify-content-center SignInPage-Container">
      {/* <LoginHeader /> */}
      <div style={{ textAlign: "center", width: "100%" }}>
        <CRow className="justify-content-center">
          <CCol md={8} className="form">
            <CRow className="d-flex align-items-center">
              <div className="thankingPage">
                <img src={ThankingImage} className="SignUpImage" />
                <h2 className="thankingPage-heading">Payment Successful !</h2>
                <p className="thankingPage-para">
                We’re pleased to inform you that your payment has been successfully completed. Your transaction has been processed smoothly, and a detailed invoice has been sent to your email address for your records. Please take a moment to review the invoice at your convenience. If you have any questions or require further assistance, our team is here to help. Thank you for your prompt payment and for choosing our services. We look forward to serving you again!
                </p>
                <CButton
                  color="dark"
                  shape="rounded-pill"
                  className="form-inputGroup_Button thankingPage-button"
                  onClick={() => {
                    navigate("/dashboard");
                    // window.location.href = "/dashboard";
                  }}
                >
                  Back to home
                </CButton>
                <div className="svg">
                  <img src={facebookIcon} className="svgIcon" />
                  <img src={linkedinIcon} className="svgIcon" />
                  <img src={youtubeIcon} className="svgIcon" />
                </div>
              </div>
            </CRow>
          </CCol>
        </CRow>
      </div>
      {/* <LoginFooter /> */}
    </div>
  );
}

export default PayUSuccessURLPage;
