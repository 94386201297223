import React from "react";
import banner_8 from "../../../../webassets/img/pricing-starter.png";
import { useSelector } from "react-redux";

function StarterPlanPaymentCard() {
  const paymentModalVisible = useSelector(
    (state) => state.plans.paymentModalVisible
  );
  return (
    <div className="col-lg-8 col-md-6" data-aos="zoom-in" data-aos-delay={200}>
      <div className="box paymentForm-boxHeight">
        <h3 style={{ color: "#65c600" }} className="mb-0">
          Starter Plan
        </h3>
        <div className="price">
          <sup>₹</sup>4999<span> +GST / mo</span>
        </div>
        <img src={banner_8} className="img-fluid" alt="" />
        <ul>
          <li>1 Administrator</li>
          <li>3 Team Members</li>
          <li>10 Mobile App Accounts</li>
          <li>10 Projects</li>
          <li>5GB Cloud Storage</li>
          <li>Add Storage - 10GB @ ₹275/mo</li>
          <li className="na">Additional Administrators</li>
          <li className="na">Add Team Members</li>
        </ul>
      </div>
    </div>
  );
}

export default StarterPlanPaymentCard;
