import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CHeader,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import {
  faArrowUpFromBracket,
  faEllipsisH,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import sortArrowsIcon from "../../assets/icons/Sorting Arrows.svg";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import closeImg from "../../assets/images/drawings/close 1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import Loader from "../loader/loader";
import API_URL from "src/utils/urls";
import { useNavigate, Link } from "react-router-dom";

import  InfoIcon  from '../../assets/icons/information.png';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

  // Function to toggle tooltip visibility
  const handleTableTooltipToggle = (index) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

function TermsAndConditions() {
  const adminId = useSelector((state) => state.dashboard.accessToken);
  const [terms, setTerms] = useState([]);
  const [termsFile, setTermsFile] = useState(null);
  const [termsName, setTermsName] = useState("");
  const [termsId, setTermsId] = useState(null);
  const [termsFileName, setTermsFileName] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [pageload, setPageLoad] = useState(true);
  const [content, setContent] = useState(null);

  const [viewType, setViewType] = useState('table'); 
  const [activeFile, setActiveFile] = useState(null); 
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState("");

  const handleFileClick = (file) => {
    setActiveFile(file);  // Set clicked file as active
  };
  
  const handleToggleChange = (event) => {
   setViewType(viewType === 'file' ? 'table' : 'file');

  };

  // confirmation model for add terms
  const [openConfirmationModal, setOpenConfirmationModel] = useState(false);
  const handleClickOpenConfirmationModel = () => {
    setOpenConfirmationModel(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModel(false);
  };

  // confirmbox
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  const [confirmDeleteFilename, setConfirmDeleteFilename] = useState(0);
  const handleConfirm = () => {
    console.log("Confirmed");
    setShowConfirmBox(false);
    setTermsId(confirmDeleteId);
    removeFromArray(terms, confirmDeleteIndex);
  };
  const handleCancel = () => {
    console.log("Cancelled");
    setShowConfirmBox(false);
  };

  const readActiveTerms = () => {
    // Fetch the blob content from the URL
    fetch(terms[0].path)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch blob content');
        }
        console.log(response.text()); // Use .text() for text-based content, .json() for JSON, etc.
      })
      .then((data) => {
        setContent(data);
        console.log(data);
        //setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blob content:', error);
        //setLoading(false);
      });
  };

  const postTermsData = async () => {
    const formData = new FormData();
    formData.append("AID", adminId);
    formData.append("file", termsFile);
    await axios
      .post(`${API_URL}saveTermsConditions`, formData)
      .then(function (response) {
        console.log(response);
        if (response.data.Result == "Failure") {
          toast.error("The Specified Blob Already Exists", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setButtonLoader(false);
          setTermsName("");
          setIsOpen(false);
          setTerms([
            ...terms,
            {
              name: termsName,
              last_update: new Date(),
            },
          ]);
        } else {
          toast.success("Terms And Conditions Saved Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsOpen(false);
          setButtonLoader(false);
          setTermsName("");
          setTerms([]);
          setSkip(0);
          pageload && getTermsAndConditions();
          setPageLoad(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setButtonLoader(false);
        alert(error);
      });
  };
  //  scrollnew
  // const [items, setItems] = useState([]);

  const getTermsAndConditions = async () => {
    try {
      const response = await axios.get(`${API_URL}termsConditions`, {
        params: {
          AID: adminId,
          skip: skip,
          limit: 10,
        },
      });
      setFirstApiLoader(false);
      const newItems = response.data;
      response.data.length && setPageLoad(false);
      if (newItems.length === 0) {
        setHasMore(false);
      } else {
        const result = JSON.stringify(response.data);
        const parsedResult = JSON.parse(result);
        // setTerms(parsedResult);
        setTerms([...terms, ...parsedResult]);
        setSkip(skip + 10);
      }
    } catch (error) {
      setFirstApiLoader(false);
      console.error("Error fetching terms and conditions:", error);
    }
  };

  const deleteTermsFile = async () => {
    await axios({
      method: "POST",
      url: `${API_URL}deleteTerms`,
      data: {
        AID: adminId,
        // TCID: termsId,
        // filename: termsFileName,
        TCID: confirmDeleteId,
        filename: confirmDeleteFilename,
      },
    }).then(
      (res) => {
        // alert("Terms and Conditions deleted successfully");
        toast.success("Terms And Conditions Deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTermsId(null);
        console.log(res);
      },
      (err) => {
        console.log(err);
        // alert(err);
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const readTerms = async () => {
    await axios
      .get(terms)
      .then((response) => {
        const result = JSON.stringify(response.data);
        console.log("terms file", result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addTerms = (e) => {
    const targetFiles = e.target.files[0];
    setTermsFile(targetFiles);
    setTermsName(e.target.files[0].name);
  };

  const submitTermsData = (e) => {
    e.preventDefault();
    if (termsFile != null) {
      setButtonLoader(true);
      postTermsData();
    } else {
      handleClickOpenConfirmationModel();
    }
  };

  const removeFromArray = (array, index) => {
    var id = array.indexOf(index);
    if (id == -1) {
      array.splice(index, 1);
    }
    setTerms(array);
  };

  useEffect(() => {
    if (terms != "") {
      console.log(terms[0].path);
      readActiveTerms();
    }
  }, [terms]);

  useEffect(() => {
    if (skip == 0) {
      setFirstApiLoader(true);
      setHasMore(true);
      getTermsAndConditions();
    }
  }, [skip]);

  useEffect(() => {
    if (termsId != null) {
      deleteTermsFile();
    }
  }, [termsId]);



  // Accordion
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // firstApiLoader
  const [firstApiLoader, setFirstApiLoader] = useState(false);

  return (
    <div>
      {showConfirmBox && (
        <ConfirmBox
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <ToastContainer />
      <div>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h2 className="page-header mb-0">Terms And Conditions
          <Tooltip title={
              <div className="custom-tooltip-content" 
              style={{ backgroundColor: '#303030', color: "white",
               margin:"5px",padding:"15px", fontFamily: "Nunito", width: "400px",
              height: "auto", fontSize: "14px",justifyContent:"center",  alignItems: "center" , borderRadius: "10px", 
               
                }}>
               

                <p>
                You can upload your company's terms and conditions here for client visibility. These terms will be accessible to your clients directly through their mobile login. Ensure they are up-to-date so your clients are always informed.
                </p>


            </div>
            }
            arrow
            placement="right"            
            >
              <img src={InfoIcon} 
              alt="Info Icon" 
            style={{ marginLeft: "10px", 
            width: "15px", height: "15px", 
            cursor: "pointer", background: "#ebedef" 
            }} />
          </Tooltip>
          </h2>
          
         {/* <div className="d-flex align-items-center" style={{display:"none !important"}}>
        <Switch 
          checked={viewType === 'file'}
          onChange={handleToggleChange}
          color="black"
          
          inputProps={{ 'aria-label': 'toggle view' }}
        />
         <span>{viewType === 'file' ?  "View table" : "View Active File"}</span>
          </div>*/}

          <div className="add-btn-doc my-3" onClick={toggleAccordion}>
            + Add Terms and conditions
          </div>
        </div>
        
        {isOpen && (
          <div className="card mb-3">
            <CCardBody>
              <form onSubmit={submitTermsData}>
                <div className="d-flex flex-wrap align-items-end">
                  <TextField
                    id="standard-basic"
                    label="Terms Name"
                    variant="standard"
                    className="accordion-textField me-3 mt-3"
                    // required
                    // disabled
                    value={termsName}
                    onChange={(e) => setTermsName(e.target.value)}
                    required
                  />
                  <div className="d-flex lex-column">
                    <input
                      type="file"
                      accept=".txt"
                     //accept=" accept="image/*,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      onChange={addTerms}
                      // multiple
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="photos-upload_label"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className="file-uploadBtn"
                      >
                        <FontAwesomeIcon
                          icon={faArrowUpFromBracket}
                          className="file-uploadIcon"
                        />
                        Choose a File
                      </Button>
                      {/* {"Photos (upto 5)"} */}
                    </label>
                  </div>
                </div>
                
        <p className="file-upload-info" style={{fontSize:'12px', marginTop:'5px'}}>
            Only Word, PDF and image files are supported.(max of 100MB)
        </p>

                <div className="d-flex flex-wrap">
                  <CButton
                    color="light"
                    shape="rounded-0"
                    className="accordion-saveBtn generalDoc-cancelBtn me-3"
                    onClick={() => {
                      //   setFile();
                      setFileContent("");    // Reset file content
                      setTermsName("");  
                      setFileName("");
                      setTermsName("");
                      setTermsFile(null);
                      toggleAccordion();
                    }}
                  >
                    Cancel
                  </CButton>
                  
                  <CButton
                    color="dark"
                    shape="rounded-0"
                    className="accordion-saveBtn generalDoc-uploadBtn"
                    type="submit"
                    disabled={termsFile == null ? true : false}
                  >

                    {buttonLoader ? <CSpinner /> : "Upload"}
                  </CButton> 
                   </div>
                  </form>                                          
            </CCardBody>
          </div>
        )}
      </div>
      
      
  {viewType === 'file' ? (
  <CCard className="active-file">
    <CCardBody>
      {firstApiLoader ? (
        <Loader datacol={4} />
      ) : (
        <InfiniteScroll
          className="file-overflow"
          height={"65vh"}
        padding={"15px"}
          dataLength={terms.length}
          next={getTermsAndConditions}
          hasMore={hasMore && terms.length >= 10}
          loader={terms.length >= 10 ? (
            <p className="text-center">Loading...</p>
          ) : null}
        >
          <div>

         {terms && terms.length > 0 ? (
          <div className="term-item">
            <h4>{terms[0].name}</h4>
            <p>Updated on: {moment(terms[0].updatedDate).format("DD-MM-YYYY")}</p>
            {/*<a href={terms[0].path}
            target="_blank"
            rel="noopener noreferrer"
            className="table-datacell_file"
            >
              View/Download File
            </a>
         <span className="table-datacell_file-active">[ACTIVE]</span>*/}
           
          </div>
            ) : (
              <p>No active file selected.</p>
            )}
            
          </div>
        </InfiniteScroll>
      )}
    </CCardBody>
  </CCard>
) : null}

      {/* table list */}

       {viewType !== 'file' && ( 

      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol>
              {firstApiLoader ? (
                <Loader datacol={4} />
              ) : (
                <InfiniteScroll

                className="table-overflow"
                height={"65vh"}
                  dataLength={terms.length}
                  next={getTermsAndConditions}
                  hasMore={hasMore && terms.length >= 10}
                  loader={
                    terms.length >= 10 ? (
                      <p className="text-center">Loading...</p>
                    ) : null
                  }
                  // endMessage={
                  //   terms.length < 10 ? (
                  //     <p className="text-center">No more items to load</p>
                  //   ) : null
                  // }
                >                
                  <CTable align="middle" className="mb-0" responsive>
                    <CTableHead className="projectList-table_header">
                      <CTableRow>
                        <CTableHeaderCell className="projectList-table_header-rowcell">
                          S.No
                        </CTableHeaderCell>
                        <CTableHeaderCell className="projectList-table_header-rowcell">
                          File Name        
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center projectList-table_header-rowcell">
                          Upload on
                        </CTableHeaderCell>
                        <CTableHeaderCell className="text-center projectList-table_header-rowcell">
                          Action
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {terms.map((item, index) => (
                        <CTableRow
                          v-for="item in tableItems"
                          key={index}
                          className="projectList-table_row"

                         onClick={() =>handleFileClick(item)} // Set the clicked file as active
                            //fetchFileContent(item.path); // Fetch content of the clicked file
                          

                        >
                          <CTableDataCell className="table-datacell text-start">
                            {index + 1}
                          </CTableDataCell>
                          <CTableDataCell className="table-datacell table-datacell_title">
                             <a
                              href={`${item.path}`}
                              target="blank"
                              className="table-datacell_file"
                            >
                              {item.name}
                              <span
                                className={`${
                                  index === 0
                                    ? "table-datacell_file-active"
                                    : ""
                                }`}
                              >
                                {index === 0 ? "  [ACTIVE]" : ""}
                              </span>
                            </a>  

                          </CTableDataCell>

                          <CTableDataCell className="text-center table-datacell">
                            {/* {moment(item.last_update)
                              .utc()
                              .format("YYYY-MM-DD")} */}
                            {moment
                              .tz(item.updatedDate, "Asia/Kolkata")
                              .format("Do MMM YYYY")}
                          </CTableDataCell>
                          <CTableDataCell className="text-center table-datacell">
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowConfirmBox(true);
                                setConfirmDeleteIndex(index);
                                setConfirmDeleteFilename(item.name);
                                setConfirmDeleteId(item.TCID);
                              }}
                            />
                          </CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>

                  
                </InfiniteScroll>                
              )}
              
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
       )} 

      <Dialog
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        className="uploadFilesHandlingModal"
      >
        <DialogContent style={{ margin: "auto" }}>
          Please select file to add terms and conditions
        </DialogContent>
        <img
          src={closeImg}
          className="drawings-modal_closeIcon"
          onClick={handleCloseConfirmationModal}
        />
      </Dialog>      
    </div>
    // </div>
  );
}
export default TermsAndConditions;
